/* eslit-disable */
/**
 *
 *
 */

import { API } from 'api';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageAlert, setCookie } from 'utils';
import SignInPresenter from './SignInPresenter';
import { actions as UserActions } from 'redux/module/userInfo';
import { useDispatch, useSelector } from 'react-redux';

const SiginInContainer = () => {
  /* Router */
  const history = useHistory();

  /* State */
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  /* Hooks */
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      MessageAlert.warning('이미 로그인되어 있습니다.');
      history.push('/messenger');
    } // eslint-disable-next-line

    // 네이버로그인
    const queryParams = new URLSearchParams(window.location.search);
    const userInfoString = queryParams.get('userInfo');

    if (userInfoString) {
      const naverUserInfo = JSON.parse(decodeURIComponent(userInfoString));
      if (window.ReactNativeWebView) {
        // 모바일이라면 모바일에 데이터 전달
        window.ReactNativeWebView.postMessage(JSON.stringify(naverUserInfo));
      } else {
        // 모바일이 아니라면 프론트에서 로그인 Api 실행
        handleLogin(naverUserInfo);
      }
    }

    // const handleMessage = (event) => {
    //   alert('모바일 -> 웹뷰 로그아웃 ! ')
    //   if (event.data === 'delete') {
    //       // 쿠키 삭제 코드
    //       //로그아웃쿠키
    //       deleteCookie('token');
    //       deleteCookie('userInfo');
    //       deleteCookie('userId');
    //       // 네이버 로그인 쿠키
    //       deleteCookie('NID_JKL');
    //       deleteCookie('NID_SES');
    //       deleteCookie('NID_AUT');
    //       deleteCookie('nid_inf');
    //       window.location.href = '/signin';

    //     }
    //   };

    //   if (window.ReactNativeWebView) {
    //     /** android */
    //     document.addEventListener("message", handleMessage);
    //     /** ios */
    //     window.addEventListener("message", handleMessage);

    //   } else {
    //     document.addEventListener("message", handleMessage);
    //     window.removeEventListener('message', handleMessage);
    //   }

    //   return () => {
    //     document.addEventListener("message", handleMessage);
    //     window.removeEventListener('message', handleMessage);
    //   };
  }, [userInfo, history]);

  /* Functions */
  const handleLogin = async (userInfo) => {
    // return;
    const { status, data } = await API.login(userInfo);

    if (status !== 200) {
      return MessageAlert.error('계정정보를 확인해주세요');
    }

    if (data) {
      setCookie('token', JSON.stringify(data));
      setCookie('userInfo', JSON.stringify(data));
      setCookie('userId', JSON.stringify(data.user_id));
      dispatch(UserActions.setSession({ users: data }));
      history.push('/messenger');
      return true;
    }
    MessageAlert.error('로그인에 실패했습니다.');
    return false;
  };

  const handleSignUp = () => {
    history.push('/signup');
  };
  return (
    <SignInPresenter handleLogin={handleLogin} handleSignUp={handleSignUp} />
  );
};

export default SiginInContainer;
