import { React } from 'react';

export const Naver = () => {
  const NAVER_CLIENT_ID = 'r7d1kOHpVSRhUHi4wELS'
  const REDIRECT_URL = encodeURI('http://server.matetalk.io/api/v1/callback')
  const STATE = 'flase'
  const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URL}`;
  const IMAGE_PATH = process.env.PUBLIC_URL + 'naver.png';

  return (
    <div>
      {/* <button onClick={NaverLogin}>네이버 로그인</button> */}
      {/* <a href={NAVER_AUTH_URL}><img  width='100%' src='http://static.nid.naver.com/oauth/small_g_in.PNG'/></a> */}
      <a href={NAVER_AUTH_URL}>
        <img width='100%' height='80px' src={IMAGE_PATH} alt="Naver Login" />
      </a>
    </div>
  );
};

export default Naver;