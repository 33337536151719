import React from 'react';
import { useHistory } from 'react-router-dom';
import MyPagePresenter from './MyPagePresenter';
import { getCookie } from 'utils';

const MyPageContainer = () => {
  const history = useHistory();
  // const [userInfo, setUserInfoData] = useState(null);
  const userInfo = JSON.parse(getCookie('userInfo'));

  const handleUserInfo = async () => {
    history.push('/myPage');
  };

  const handlePaymentHistory = () => {
    // const paymentData = await
    history.push('/payHistory');
    return <div> 여기에 결제 정보 출력</div>;
  };
  return (
    <MyPagePresenter
      userInfo={userInfo}
      handleUserInfo={handleUserInfo}
      handlePaymentHistory={handlePaymentHistory}
    />
  );
};

export default MyPageContainer;
