import React from 'react';
import USER_UPDATE from 'assets/svg/pencil.svg';
import JOIN_MENU from 'assets/svg/eraser.svg';
import { SUCCESS_CODE, deleteCookie } from 'utils';
import { Button } from 'antd';
import { API } from 'api';

import './myPage.css';

const MyPagePresenter = ({
  userInfo,
  handleUserInfo,
  handlePaymentHistory,
}) => {
  console.log('쿠키에서 가지고온 유저데이터 : ', userInfo);
  /* eslint-disable no-restricted-globals */
  const handleUnregister = async () => {
    var userResponse = confirm('정말로 회원 탈퇴를 하시겠습니까?');

    // 사용자의 선택에 따라 다른 처리
    if (userResponse) {
      const { status } = await API.LeaveService(userInfo.user_id);
      if (status === SUCCESS_CODE) {
        //로그아웃쿠키
        deleteCookie('token');
        deleteCookie('userInfo');
        deleteCookie('userId');
        // 네이버 로그인 쿠키
        deleteCookie('NID_JKL');
        deleteCookie('NID_SES');
        deleteCookie('NID_AUT');
        deleteCookie('nid_inf');

        alert('회원 탈퇴가 정상적으로 처리되었습니다.');

        window.location.href = '/signin';
      }
    } else {
      alert('회원 탈퇴를 취소하셨습니다.');
    }
  };
  /* eslint-enable no-restricted-globals */

  return (
    <div className='join-container'>
      <div className='join-flex-box'>
        <div className='option-select-box'>
          <svg
            viewBox='64 64 896 896'
            focusable='false'
            data-icon='user'
            width='100px'
            height='100px'
            fill='currentColor'
            aria-hidden='true'
          >
            <path d='M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z'></path>
          </svg>
          <h2>
            {' '}
            <b>{userInfo.user_name}</b> 님
          </h2>
          <hr />
          <br />
          <div
            className='create-room-box room-box'
            onClick={() => {
              handleUserInfo();
            }}
          >
            <img src={USER_UPDATE} alt='' />
            <span className='menu-title'> 정보수정</span>
          </div>
          <br />
          <div
            className='join-room-box room-box'
            onClick={() => {
              handlePaymentHistory();
            }}
          >
            <img src={JOIN_MENU} alt='' />
            <span className='menu-title'> 결제내역</span>
          </div>
        </div>
        <div className='option-view'>
          <image>My Page</image>
          <h2> 회원정보 </h2>
          <div>
            닉네임 : {userInfo.user_account} <br />
            등록된 전화번호 : {userInfo.phone_number}
          </div>
          <Button type='danger' size='large' onClick={handleUnregister}>
            회원탈퇴
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MyPagePresenter;
