import dotenv from 'dotenv';
import * as firebaseApp from 'firebase/app';
dotenv.config();

export { default as ApiManager } from './ApiManager';
export { default as MessageAlert } from './MessageAlert';
export { default as TypeManager } from './TypeManager';
//NOTE : 파이베이스 구성 및 ENv 설정 변경
export const PUBLIC_URL = process.env.REACT_APP_HOST_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
// API Status Code
export const SUCCESS_CODE = 200;
export const FAILURE_CODE = 400;

const deployType = process.env.REACT_APP_DEPLOY_TYPE
  ? process.env.REACT_APP_DEPLOY_TYPE
  : process.env.NODE_ENV;

export const HOST_URL = process.env.REACT_APP_HOST_URL;

export const envType = deployType;

//FCM CONFIG
export const VAPID_PUBLIC_KEY =
  'BJt_fqRp0kDeIMVfvbqfhCg5UPY_LXQ0AecoaXbjwH2iRXFAfo7py9MnxLQiARrT-JOTb_pWn1FMIU6Pmc1HL-I';

export const FireBaseInstance = firebaseApp.initializeApp({
  apiKey: 'AIzaSyBUej7Io1WGX6vABIBdWRQ1Xon1TS9MBD0',
  authDomain: 'serverlog-d5700.firebaseapp.com',
  projectId: 'serverlog-d5700',
  storageBucket: 'serverlog-d5700.appspot.com',
  messagingSenderId: '954413851978',
  appId: '1:954413851978:web:8ffd97f277714898850db3',
  measurementId: 'G-VTN8E68DH9',
});

export const getCookie = (name, options = null) => {
  const value = window.document.cookie.match(
    '(^|;) ?' + name + '=([^;]*)(;|$)'
  );
  return value ? decodeURIComponent(value[2]) : null;
};
// setCookie
export const setCookie = (name, value, expires = 1, callback = false) => {
  var date = new Date();
  date.setTime(date.getTime() + expires * 1000 * 60 * 60 * 24);
  window.document.cookie = `${name}=${encodeURIComponent(
    value
  )};expires=${date.toUTCString()}; path=/`;
  if (callback) callback();
};

// clearCookie
// export const deleteCookie = (name, { path, domain }) => {
export const deleteCookie = (name) => {
  var date = new Date();
  date.setTime(date.getTime() - 1000);
  if (getCookie(name)) {
    window.document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    // window.document.cookie =
    //   name +
    //   '=' +
    //   (path ? ';path=' + path : '') +
    //   (domain ? ';domain=' + domain : '') +
    //   ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
};

/**
 * API파라미터 Replace함수
 * --
 */
export const parameterToPath = (path, params = {}) => {
  const keys = Object.keys(params);
  let newStr = path;
  for (let key of keys) {
    newStr = newStr.replace(`:${key}`, params[key]);
  }
  return newStr;
};
