export const DOCUMENT_SHARING_CHANGE = 'DOCUMENT_SHARING_CHANGE';
export const SHARE_DOCUMENT_FILE = 'SHARE_DOCUMENT_FILE';
export const MODERATOR_SELECT_IMAGE = 'MODERATOR_SELECT_IMAGE';
export const BROADCAST_VOTE = 'BROADCAST_VOTE';
export const SHARE_DRAWING_DATA = 'SHARE_DRAWING_DATA';
export const ADD_DRAWING_SHEET = 'ADD_DRAWING_SHEET';
export const DRAWING_TO_SHEET = 'DRAWING_TO_SHEET';
export const DRAWING_TO_DOCUMENT = 'DRAWING_TO_DOCUMENT';

export const REQUEST_SAY = 'REQUEST_SAY';
